<template>
    <div
            class="rounded-lg"
            :class="edit && !inItem ? 'grid-item':''"
            :style="inItem ? {height: width + 'px'} : $vuetify.breakpoint.width < 600 ? {gridColumn: 'span 1', gridRow: 'span 1'}:{gridColumn: 'span ' + display_rules.cols, gridRow: 'span ' + display_rules.rows}"
            ref="child"
    >
        <div style="position: relative; overflow: hidden" class="rounded-lg paragraph fill-height white--text elevation-2 cell text-center">
            <v-hover v-slot:default="{ hover }">
                <v-row
                        align="center"
                        no-gutters
                        class="transition-cubic pa-2"
                        :class="edit ? 'handle':''"
                        :style="(hover || hover_menu) && edit ? {backgroundColor: $tools.transparentize('black2white', 0.1, $vuetify)}:{}"
                >
                    <v-col cols="auto" class="pr-2" :class="edit? '':'pb-1'">
                        <v-icon :size="edit ? 20 : 16">{{ edit ? 'mdi-drag-horizontal-variant' : widget.icon }}</v-icon>
                    </v-col>
                    <v-col class="text-truncate black2white--text">
                        <Label :value="widget.display_name" small @hover="(e) => hover_menu = e" :background="$tools.transparentize('cell', 1, $vuetify)" :classes="'black2white--text'" />
                    </v-col>
                    <v-col cols="auto" class="pl-2">
                        <v-icon class="cursor-pointer" @click="$emit('delete')" size="20" v-if="edit && !inItem">mdi-close</v-icon>
                        <v-menu left v-else rounded="lg">
                            <template v-slot:activator="{ on }">
                                <v-icon class="cursor-pointer" size="18" v-on="on">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list class="rounded-lg overflow-hidden py-0" dense>
                                <v-list-item link @click="type === 'table' ? type = widget_data.type : type = 'table'">
                                    <v-list-item-content class="paragraph">Table</v-list-item-content>
                                </v-list-item>
                                <v-list-item link>
                                    <v-list-item-content class="paragraph">Exporter</v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-hover>
            <div
                    class="overflow-hidden"
                    style="position: absolute; bottom: -4px; right: -4px; width: 40px; height: 40px; z-index: 1;"
                    v-show="edit"
            >
                <v-hover v-slot:default="{ hover }">
                    <div
                            @mouseenter="(e) => makeResizableDiv(e)"
                            class="text-center transition-cubic"
                            :style="hover ? {backgroundColor: $tools.transparentize('black', 0.2, $vuetify)}:{}"
                            style="position: relative; bottom: -4px; right: -15px;transform: rotateZ(45deg); height: 60px; display: flex; align-items: center; justify-content: start; cursor: nwse-resize"
                    >
                        <v-icon color="black2white" size="30" style="position: relative; top: 0px; left: -4px">mdi-chevron-right</v-icon>
                    </div>
                </v-hover>
            </div>
            <div :class="widget.type === 'table' ? '': widget.type === 'value' ? 'pb-2' : 'pa-2'" style="height: calc(100% - 36px)">
                <div class="fill-height" ref="container">
                    <div v-if="widget_data !== null && widget_data.items.length !== 0" class="fill-height">
                        <Table v-if="type === 'table'" :widgetData="widget_data" />
                        <GaugeMinMax v-else-if="type === 'gauge-min-max'" :width="width" :height="height" :widgetData="widget_data" />
                        <LineChart v-else-if="type === 'line-chart'" :width="width" :height="height" :widgetData="widget_data" @refreshData="fetchWidgetData" />
                        <BarChart v-else-if="type === 'bar-chart'" :width="width" :height="height" :widgetData="widget_data" @refreshData="fetchWidgetData" />
                        <Data v-else-if="type === 'value'" :width="width" :height="height" :widgetData="widget_data" />
                        <Indicator v-else-if="type === 'indicator'" :width="width" :height="height" :widgetData="widget_data" />
                    </div>
                    <div v-else-if="widget_data !== null && widget_data.items.length === 0 && ready" style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: calc(100% - 12px);">
                        <v-icon size="30" color="cell3">mdi-block-helper</v-icon>
                        <div class="mt-3 sub-title-2 cell3--text">{{ $t('single_trad.Table.no_data') }}</div>
                    </div>
                    <div v-else-if="widget_data === null && error" style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: calc(100% - 12px);" class="pa-3">
                        <v-icon size="30" color="error">mdi-alert</v-icon>
                        <div class="mt-3 paragraph error--text">{{ error }}</div>
                    </div>
                    <div v-else style="display: flex; align-items: center; justify-content: center; height: calc(100% - 12px);">
                        <v-progress-circular indeterminate size="40" color="primary"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name: "Widget",
        props: ['widget', 'edit' , 'cols', 'path', 'inItem'],
        components: {
		    Label: () => import('@/components/ui/Label.vue'),
            Table: () => import('@/components/widgets/Table.vue'),
            GaugeMinMax: () => import('@/components/widgets/GaugeMinMax.vue'),
            LineChart: () => import('@/components/widgets/LineChart.vue'),
            BarChart: () => import('@/components/widgets/BarChart.vue'),
            Data: () => import('@/components/widgets/Data.vue'),
            Indicator: () => import('@/components/widgets/Indicator.vue'),
        },
        data(){
		    return {
		        hover_menu: false,
                widget_data: null,
                height: 0,
                width: 0,
                ready: false,
                error: false,
                type: 'table'
            }
        },
        computed: {
            display_rules: {
                get(){
                    return this.widget.display_rules || null
                },
                set(val) {
                    this.$set(this.widget, 'display_rules', val)
                }
            }
        },
        methods: {
		    fetchWidgetData(params={}){
                this.$wsc.getList(this.path + 'widget/'+ this.widget.id, params, success => {
                    this.widget_data = success
                    this.type = success.type
                    this.ready = true
                }, fail => { this.error = fail })
            },
            makeResizableDiv(div) {
                const handle = div.target
                const child = div.target.parentElement.parentElement
                const container = div.target.parentElement.parentElement.parentElement

                let that = this

                handle.addEventListener('mousedown', function(e) {
                    e.preventDefault()
                    window.addEventListener('mousemove', resize)
                    window.addEventListener('mouseup', stopResize)
                })

                function resize(e) {
                    let newPosX = e.pageX - (child.getBoundingClientRect().left - 10)
                    let newPosY = e.pageY - (child.getBoundingClientRect().top - 10) - window.scrollY

                    let dX = child.getBoundingClientRect().right - container.getBoundingClientRect().right
                    let dY = child.getBoundingClientRect().bottom - container.getBoundingClientRect().bottom

                    child.style.width = newPosX + 'px'
                    child.style.height = newPosY + 'px'

                    if(dX > 30){
                        if(that.display_rules.cols + 1 <= that.cols) {
                            that.$set(that.display_rules, 'cols', that.display_rules.cols + 1)
                        }
                    }
                    if(dX < -60){
                        if(that.display_rules.cols - 1 >= that.display_rules.min_cols)
                            that.$set(that.display_rules, 'cols', that.display_rules.cols - 1)
                    }
                    if(dY > 30 && !that.inItem){
                        that.$set(that.display_rules, 'rows', that.display_rules.rows + 1)
                    }
                    if(dY < -60 && !that.inItem){
                        if(that.display_rules.rows - 1 >= that.display_rules.min_rows)
                            that.$set(that.display_rules, 'rows', that.display_rules.rows - 1)
                    }
                }

                function stopResize() {
                    child.style.removeProperty('width');
                    child.style.removeProperty('height');

                    window.removeEventListener('mousemove', resize)
                }
            },
            computeShape(){
                if(this.display_rules === null) {
                    if (this.widget.type === 'line-chart' || this.widget.type === 'bar-chart') {
                        this.display_rules = {
                            min_cols: 6,
                            min_rows: 4,
                            cols: 6,
                            rows: 4
                        }
                    }
                    else if (this.widget.type === 'gauge-min-max') {
                        this.display_rules = {
                            min_cols: 3,
                            min_rows: 3,
                            cols: 3,
                            rows: 3
                        }
                    }
                    else if (this.widget.type === 'table') {
                        this.display_rules = {
                            min_cols: 4,
                            min_rows: 5,
                            cols: 4,
                            rows: 5
                        }
                    } else {
                        this.display_rules = {
                            min_cols: 2,
                            min_rows: 2,
                            cols: 2,
                            rows: 2
                        }
                    }
                }
            },
            computeResolution(entries){
		        this.width = entries[0].target.clientWidth
		        this.height = entries[0].target.clientHeight
                this.$emit('loaded', entries[0].target)
            }
        },
        created() {
            this.computeShape()
            this.fetchWidgetData()
        },
        mounted() {
            new ResizeObserver(this.computeResolution).observe(this.$refs.container)
        }
    }
</script>

<style scoped>
    .handle {
        cursor: grab;
    }
    .grid-item {
        align-items: center;
        box-shadow: 0 0 0 2px rgba(0, 120, 255, 0.5) inset;
        background-color: rgba(0, 120, 255, 0.5) !important;
    }
</style>
